body {
  margin: 0;
  font-family: 'objectsans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b212d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #008de4;
  text-decoration: none;
}

p {
  margin: 0;
}

input {
  -webkit-appearance: none;
}

textarea {
  font-family: inherit;
  resize: none;
}

input:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}

select:focus {
  outline: 0;
}

*,
input,
button {
  box-sizing: border-box;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

@font-face {
  font-family: 'objectsans';
  src: local('objectsans'), url(./fonts/objectsansthin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'objectsans';
  src: local('objectsans'), url(./fonts/objectsans.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'objectsans';
  src: local('objectsans'), url(./fonts/objectsansbold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'eiko';
  src: local('eiko'), url(./fonts/eikolight.otf) format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'eiko';
  src: local('eiko'), url(./fonts/eiko.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'eiko';
  src: local('eiko'), url(./fonts/eikoheavy.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8d9096;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8d9096;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8d9096;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #a4a6ab;
}

.quill > .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 0.1px solid #303b5b !important;
  border-top: none !important;
}

.quill > .ql-container > .ql-editor {
  min-height: 150px;
}

.ql-stroke {
  fill: none;
  stroke: #a4a6ab;
}

.ql-fill {
  fill: #a4a6ab;
  stroke: none;
}

.ql-stroke:active {
  fill: none;
  stroke: red;
}

.ql-fill:active {
  fill: red;
  stroke: none;
}

.ql-stroke:focus {
  fill: none;
  stroke: red;
}

.ql-fill:focus {
  fill: red;
  stroke: none;
}

.ql-picker {
  color: #a4a6ab !important;
}
